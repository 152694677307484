<template>
  <div class="pt-6">
    <div class="w-11/12 mx-auto">
      <div id="cart_content" class="max-w-screen-xl mx-auto">
        <!-- Maybe change this into a form. Once proceed to checkout, we will generate a order with data in cart -->
        <div class="divide-y divide-black">
          <h1 class="text-center text-lg">Order Form</h1>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
